body, html {
  height: 100%;
  width: 100%;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif
}

.home,
.about-us,
.e-waste,
.intitiatives,
.support-us,
.volunteer,
.contact,
.events,
.three-arms,
.meet-us,
.gallery {
  min-height: 100vh;
  position: relative;
  /* margin-top: 5rem; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: 3rem; */
}

/* .about-us,
.e-waste,
.intitiatives,
.support-us,
.volunteer,
.contact {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
} */

@font-face {
  font-family: ubuntu-bold;
  src: url(./fonts/Ubuntu-Bold.ttf);
}

@font-face {
  font-family: ubuntu-medium;
  src: url(./fonts/Ubuntu-Medium.ttf);
}

@font-face {
  font-family: nunito-medium;
  src: url(./fonts/Nunito-Medium.ttf);
}

@font-face {
  font-family: nunito-black;
  src: url(./fonts/Nunito-Black.ttf);
}

@font-face {
  font-family: nunito-extra-bold;
  src: url(./fonts/Nunito-ExtraBold.ttf);
}

@font-face {
  font-family: nunito-bold;
  src: url(./fonts/Nunito-Bold.ttf);
}


@keyframes spinner {
  0% {transform: rotate(0deg); border-width: 0.625rem;}
  50% {transform: rotate(180deg); border-width: 0.375rem;}
  100% {transform: rotate(360deg); border-width: 0.625rem;}
}

@keyframes logo-spinner {
  0% {transform: rotate(0deg);}
  50% {transform: rotate(180deg);}
  100% {transform: rotate(360deg);}
}