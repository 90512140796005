.acctech-container{
    display: flex;
    flex-direction: column;
    background: white;
    color: #2585BB;
    width: 100%;
}

.acctech-wrapper{
    background: white;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    height: 35rem;
    width: 100%;
    margin-top: 1rem;
    flex-direction: row;
}

.using-tech{
    width: 800px;
    opacity: 0;
    transform: translateX(25%);
    transition: all ease 1.25s;
    margin-left: 1rem;
    width: 480px;
    height: 300px;
    border-radius: 1rem;
    opacity: 0;
    transform: translateX(25%);
    transition: all ease 1.25s;
    margin-left: 1rem;
}

.using-tech.visible {
    opacity: 1;
    transform: translateX(0);
}

.acctech-mission-wrapper{
    opacity: 0;
    transition: opacity ease 1.25s;
    max-width: 38vw;
    display: flex;
    flex-direction: column;
}

.acctech-mission-heading{
    font-family: nunito-black;
    font-size: 4rem;
    color: #2585BB;
    margin-bottom: 2rem;
}

.acctech-mission-statement{
    font-family: nunito-extra-bold;
    font-size: 1.6rem;
    color: #2585BB;
}

.acctech-mission-wrapper.visible{
    opacity: 1;
}

@media screen and (max-width: 1000px) {
    /* .using-tech {
        display: none;
    } */

    .acctech-mission-heading {
        font-size: 3rem;
    }

    .acctech-mission-statement {
        font-size: 1.5rem;
    }

}

@media screen and (max-width: 900px) {
    .using-tech {
        display: none;
    }

    .acctech-container {
        width: 100%;
        /* margin: 0 auto; */
        /* display: flex;
        align-items: center; */
        text-align: center;
    }

    .acctech-wrapper {
        height: fit-content;
        width: fit-content;
        /* margin-left: auto;
        margin-right: auto; */
    }

    .acctech-mission-wrapper.visible {
        text-align: center;
        height: fit-content;
        width: 100%;
        margin: 4rem 0 0 0;
    }

    
    .acctech-mission-statement {
        font-size: 1.5rem;
        width: 100%;
    }

}