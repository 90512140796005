.testimonial-container{
    display: flex;
    flex-direction: column;
    background: white;
}

.testimonial-wrapper{
    background: #244a64;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    height: 35rem;
    width: 100%;
    flex-direction: row;
}
/* 
.using-tech{
    width: 480px;
    height: 300px;
    border-radius: 1rem;
    opacity: 0;
    transform: translateX(25%);
    transition: all ease 1.25s;
    margin-left: 1rem;
}

.using-tech.visible {
    opacity: 1;
    transform: translateX(0);
} */

.stat-div {
    margin: 0 auto;
    text-align: center;
    width: 20rem;
}

.stat-header {
    font-size: 2.5rem;
    font-family: nunito-extra-bold;
    color: #000000;
}

.header-underline {
    width: 2rem;
    height: 0.5rem;
    background-color: #244a64;
    border-radius: 1rem;
    margin: 1rem auto;
}

.stat-content {
    font-size: 1.5rem;
    font-family: nunito-bold;
    color: #000000;
}

.stat-underline {
    display: none;
}

.stories-wrapper{
    opacity: 0;
    transition: opacity ease 1.25s;
    max-width: 38vw;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.stories-heading{
    font-family: nunito-extra-bold;
    font-size: 4rem;
    color: #fff;
    text-shadow: 0px 4px 40px #000000;
    margin-bottom: 2rem;
}

.stories-statement{
    font-family: nunito-bold;
    font-size: 2rem;
    color: #fff;
}

.stories-wrapper.visible{
    opacity: 1;
}

.stories-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    background-color: white;
}

.divider{
    height: 1px;
    width: 60%;
    display: flex;
    flex-direction: column;
    background-color: black;
    margin: 0 auto;
}

.story-desc {
    height: fit-content;
    width: 75rem;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-top: 4rem;
    -moz-box-shadow: 0px 0px 10px #838383;
    -webkit-box-shadow: 0px 0px 10px #838383;
    box-shadow: 0px 0px 10px #838383;
    border: none;
    font-family: nunito-bold;
    align-items: center;
    justify-content: center;
    color: #44bfd2;
    border-top-left-radius:9px;
    border-top-right-radius:9px;
    border-bottom-left-radius:9px;
    border-bottom-right-radius:9px;
}

.title-container {
    width: 100%;
    height: 15rem;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.divider{
    height: 1px;
    width: 80%;
    display: flex;
    flex-direction: column;
    background-color: black;
    margin: 0 auto;
}

.arms-title.visible {
    opacity: 1;
}

.stories-title{
    margin-bottom: 3rem;
    font-size: 2rem;
}

.stories-content {
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
}

.story-text-container {
    width: 70rem;
    padding: 1rem;
    margin-top: 3rem;
}

.story-headers {
    font-family: nunito-extra-bold;
    font-size: 2rem;
    color: #44bfd2;
    line-height: 2.5rem;
}

.story-paragraphs {
    font-family: nunito-bold;
    color: #2c313f;
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin-top: 1rem;
    padding-left: 3rem;
}

.quote-right{
    font-size: 2rem;
    color: #2c313f;
    margin-left: 62rem;
}

.quote-left{
    font-size: 2rem;
    color: #2c313f;
}
 
@media screen and (max-width: 1000px) {
    
    .stories-content {
        padding: 0;
        margin: 0rem;
        text-align: center;
        width: 100vw;
    }

    .divider{
        width: 50%;
        margin: 0;
    }    

    .stories-title{
        width: 22rem;
        font-size: 1.3rem;
        text-align: center;
    }

    .story-desc{
        width: 100vw;
        align-self: center;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 3rem;
        -moz-box-shadow: 0px 0px 0px #000000;
        -webkit-box-shadow: 0px 0px 0px #000000;
        box-shadow: 0px 0px 0px #000000;
        color: #44bfd2;
        border-radius: 0;
    }

    .quote-right{
        display: none;
    }

    .quote-left{
        display: none;
    }

    .story-text-container {
        width: 100vw;
        margin-top: 1rem;
        padding: 0;
    }

    .story-headers {
        font-size: 1.3rem;
        width: 100vw;
        margin: 1rem 0 1rem 0;
    }

    .story-paragraphs{
        font-size: 0.8rem;
        width: 20rem;
        text-align: center;
        margin: 0 auto 0 auto;
        padding: 0rem;
    }
/*     
    .using-tech{
        display: none;
    } */

    .stories-wrapper {
        max-width: fit-content;
        padding: 0 2rem;
    }

    .stories-heading {
        font-size: 3rem;
        text-align: center;
    }

    .stories-statement {
        font-size: 1.3rem;
        text-align: center;
    }

    .stat-header {
        font-size: 2rem;
    }

    .stat-content {
        font-size: 1rem;
    }

}

@media screen and (max-width: 750px) {
    
    .challenge-stats-container {
        width: 100%;
        border-radius: 0;
        margin: 0;
        margin-top: 3rem;
        height: fit-content;
        box-shadow: none;
        display: flex;
        flex-direction: column;
    }

    .stat-div {
        margin: 2rem auto;
    }

    .stat-underline {
        width: 85%;
        margin: 0 auto;
        height: 2px;
        display: initial;
        display: block;
        background-color: #244a64;
    }

}