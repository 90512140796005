.stats-container{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10rem;
    margin-top: 5rem;
}

.stats-content {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.stats-title{
    font-family: ubuntu-bold;
    color: #244a64;
    font-size: 3.5rem;
}

.devices-collected{
    font-family: ubuntu-bold;
    font-size: 2.5rem;
    color: #244a64;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
}

.countup-container {
    height: fit-content;
}

.countup{
    font-family: ubuntu-bold;
    color: black;
    font-size: 2rem;
}

.stats-icon{
    font-size: 5rem;
    color: #44bfd2;
    margin: 2.5rem;
}

.device-stat-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.device-stats {
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.laptop-img {
    transform: rotateY(180deg);
    width: 8rem;
}

.phone-img {
    width: 8rem;
}

.tablet-img {
    transform: rotateY(180deg);
    width: 8rem;
}

.device-countup{
    font-family: ubuntu-bold;
    color: black;
    font-size: 2.5rem;
    bottom: 0;
}

@media screen and (max-width: 1200px) {

    .stats-title{
        font-size: 2.25rem;  
    }

    .devices-collected{
        font-size: 1.5rem;
    }

    .device-stats {
        display: flex;
        flex-direction: column;
        margin: 1rem;
    }

    .device-countup{
        font-size: 1.5rem;
    }
    
    .laptop-img {
        width: 4rem;
    }
    
    .phone-img {
        width: 4rem;
    }
    
    .tablet-img {
        width: 4rem;
    }
    
}