.card-container{
    height: fit-content;
    width: 25rem;
    min-height: 20rem;
    border-radius: 8px;
    -moz-box-shadow: 0px 0px 18px #000000;
    -webkit-box-shadow: 0px 0px 18px #000000;
    box-shadow: 0px 0px 18px #000000;
    background-color: rgb(255, 255, 255);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    margin-left: 6rem;
    margin-right: 6rem;
    margin-bottom: 2rem;
    justify-content: space-between;
}

.card-title{
    font-family: ubuntu-bold;
    color: #244a64;
    font-size: 1.585rem;
}

.card-description{
    font-family: ubuntu-medium;
    font-size: 1.3rem;
}

.link-container {
    height: fit-content;
    width: fit-content;
    cursor: pointer;
    outline: none;
}

.card-link {
    font-family: ubuntu-bold;
    text-decoration: none;
    color: #244a64;
    font-size: 1.585rem;
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    outline: none;
}

.arrow {
    margin-left: 0.5rem;
    font-size: 1.2rem;
    color: #244a64;
    outline: none;
    transition: all 0.3s ease-out;
}

.card-link:hover, .card-link:hover .arrow {
    color: #44bfd2;
}

@media screen and (max-width: 1200px) {
    .card-container{
        height: fit-content;
        width: fit-content;
        margin-left: 1.075rem;
        margin-right: 1.075rem;
        padding: 1.5rem;
        min-height: 15rem;
        margin-bottom: 2rem;
        justify-content: space-evenly;
    }
    
    .card-title{
        font-size: 1.35rem;
    }

    .card-description{
        font-size: 1.1rem;
        line-height: 1.25rem;
    }

    .card-link{
        font-size: 1.3rem;
    }
}