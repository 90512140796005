.main-impact-container {
    width: 100%;
    margin-top: -10rem;
    margin-bottom: 10rem;
}

.main-impact-heading {
    font-family: nunito-black;
    font-size: 4rem;
    color: white;
    margin-bottom: 2rem;
}

.main-impact-wrapper {
    display: flex;
    flex-direction: row;
}

.main-impact-stats {
    height: fit-content;
    width: 25rem;
    min-height: 17rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 8px rgb(88, 88, 88);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 0 2rem 2rem 2rem;
    text-align: center;
    justify-content: space-around;
    border-radius: 1rem;
}

.main-impact-stat-icon{
    font-size: 4rem;
    color: #2585BB;
    margin: 0 auto;    
}

.main-impact-stat-desc{
    font-family: nunito-extra-bold;
    font-size: 3rem;
    color: #2585BB;
}

.main-impact-stat-text{
    font-family: nunito-extra-bold;
    color: #2585BB;
    line-height: 3.25rem;
    font-size: 2rem;
}

.main-impact-countup-stat{
    font-family: nunito-extra-bold;
    color: #2585BB;
    font-size: 3rem;
    margin-bottom: 1rem;
}