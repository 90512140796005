.impact-container{
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 5rem auto 0 auto;
    padding: 2rem;
    border-radius: 1rem;
    background: #2585BB;
    margin-bottom: 5rem;
}

.impact-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.bullseye-icon{
    font-size: 4rem;
    align-content: center;
    margin: 0 auto;
    color: white;
}

.impact-title{
    font-family: nunito-extra-bold;
    font-size: 4rem;
    text-align: center;
    color: white;
    margin-bottom: 1rem;
}

.first-event{
    font-family: nunito-extra-bold;
    font-size: 2.25rem;
    text-align: center;
    color: white;
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.stat{
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
}

.stat-icon{
    font-size: 4rem;
    color: #2585BB;
    margin: 0 auto;    
}

.countup-stat{
    font-family: nunito-extra-bold;
    color: #2585BB;
    font-size: 3rem;
    margin-bottom: 1rem;
}

.countup-stat-text{
    font-family: nunito-extra-bold;
    color: #2585BB;
    line-height: 3.25rem;
    font-size: 2rem;
}

.stat-desc{
    font-family: nunito-extra-bold;
    font-size: 3rem;
    color: #2585BB;
}

.impact-stats{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.impact-desc{
    font-family: nunito-bold;
    font-size: 1.5rem;
    color: white;
    text-align: center;
}

.stat-card-container-one, .stat-card-container-two{
    height: fit-content;
    width: 25rem;
    min-height: 17rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 8px rgb(88, 88, 88);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 0 1rem 2rem 1rem;
    text-align: center;
    justify-content: space-between;
    border-radius: 1rem;
    opacity: 0;
    transition: all ease 1.25s;
}

@media screen and (max-width: 1200px) {
    
    .bullseye-icon {
        font-size: 3.5rem;
    }

    .impact-title{
        font-size: 3rem;
    }

    .first-event {
        font-size: 2rem;
    }

    .stat-card-container-one, .stat-card-container-two {
        width: 17rem;
        min-height: 14rem;
    }

    .stat-icon {
        font-size: 2.5rem;
    }

    .stat-desc {
        font-size: 2rem;
    }

    .countup-stat {
        font-size: 2rem;
    }

    .countup-stat-text {
        font-size: 1.5rem;
    }

}

@media screen and (max-width: 750px) {
    .impact-container {
        border-radius: 0;
        width: 100%;
        margin-bottom: 0rem;
    }

    .impact-stats {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}