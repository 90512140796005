.navbar {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 999;
    -moz-box-shadow: 0px 0px 10px #000000;
    -webkit-box-shadow: 0px 0px 10px #000000;
    box-shadow: 0px 0px 10px #858585;
}

.NeuTech-Logo{
    margin: auto;
    width: 170px;
    outline: none;
}

.navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color:#2585BB;
    height: 80px;
    max-width: 1500px;
}

.navbar-logo{
    color: white;
    /* margin-left: 20px; */
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    outline: none;
}

.NeuTech-Logo.textless {
    display: none;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(7, auto);
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-item{
    height: 80px;
}

.nav-links {
    color: #2FB7F1;
    display: flex;
    font-family: ubuntu-bold;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    outline: none;
    transition: all 0.1s ease-in-out;
    border-bottom: 0px solid #2585BB;
}

.nav-links.init-id-nav-item {
    border-bottom: 4px solid #2585BB;
    color: #2585BB;
    transition: all 0.1s ease-in-out;
}

.nav-links:hover {
    border-bottom: 4px solid #2585BB;
    color: #2585BB;
}

#home, #about-us, #initiatives, #support-us, #events, #gallery {
    border-bottom: 4px solid #2585BB;
    color: #2585BB;
}

#initiatives1, #initiatives2, #initiatives3 {
    background-color: #2FB7F1;
    color: #ffffff;
}

.fa-bars{
    color: white;
}

.nav-links-mobile{
    display: none;
}

.menu-icon{
    display: none;
}

.initiatives-wrapper {
    color: #2FB7F1;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 0%;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    -moz-box-shadow: 0px 1px 6px #000000;
    -webkit-box-shadow: 0px 1px 6px #000000;
    box-shadow: 0px 1px 6px #858585;
    border-radius: 0rem 0rem 0.625rem 0.625rem;
}

.initiatives-wrapper.visible {
    height: 150px;
}

.initiative-links {
    color: #2FB7F1;
    text-decoration: none;
    font-size: 1rem;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-family: ubuntu-bold;
}

.initiative-links:hover {
    background-color: #2FB7F1;
    color: #ffffff;
}

@media screen and (max-width: 1200px) {

    .NavbarItems {
        /* position: relative; */
    }

    .nav-menu{
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        color: #fff;
        left: 0;
        top: 0;
        margin-top: 80px;
        transform: translateX(-200%);
        opacity: 1;
        transition: all 0.5s ease;
        background: #47a0d3;
        align-items: center;
        text-align: center;
        z-index: 1;
        -moz-box-shadow: 0px 0px 10px #000000;
        -webkit-box-shadow: 0px 0px 10px #000000;
        box-shadow: 0px 0px 10px #858585;
    }

    .nav-menu.active {
        height: fit-content;
        transform: translate(0%, 0%);
        opacity: 1;
        z-index: 1;
    }

    .nav-item {
        width: 100%;
        height: 100%;
        font-family: ubuntu-bold;
        color: #fff;
        text-align: center;
    }

    .nav-links {
        width: 100%;
        margin: 0 auto;
        color: #fff;
        height: 100%;
        font-family: ubuntu-bold;
        display: block;
        color: #fff;
        text-align: center;
        font-size: 1.75rem;
        padding: 2.5rem;
    }
    
    #home, #about-us, #initiatives, #support-us, #events {
        border: none;
        color: #fff;
    }

    #home:hover, #about-us:hover, #initiatives:hover, #support-us:hover, #events:hover {
        background-color: #93d7ff;
        color: #2585BB;
        border: none;
    }
    
    .nav-links:hover {
        background-color: #93d7ff;
        color: #2585BB;
        border: none;
    }

    .navbar-logo {
        position: absolute;
        left: 0;
        transform: translateX(25%);
    }

    .menu-icon{
        display: flex;
        flex-direction: row;
        position: absolute;
        margin-right: 2rem;
        margin-top: 5px;
        color: #2585BB;
        right: 0;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .menu-text{
        font-size: 1.3rem;
        margin-left: 10px;
        line-height: 2rem;
        color: #2585BB;
        font-family: ubuntu-bold;
    }

    .fa-times{
        color: white;
        font-size: 2rem;
    }

    .nav-links-mobile {
        /* display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid white;
        transition: all 0.3s ease-out; */
    }
    
    /* .nav-links-mobile:hover {
        background: white;
        color: #242424;
        transition: 250ms;
    } */
}

@media screen and (max-width: 450px) {

    .NeuTech-Logo {
        display: none;
    }

    .NeuTech-Logo.textless {
        display: inherit;
        width: 4rem;
        transform: translateX(25%);
    }

    .menu-text {
        display: none;
    }

}