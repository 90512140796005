.founders-container{
    display: flex;
    flex-direction: column;
    margin: 4rem 25rem 2rem 25rem;
    padding-bottom: 25rem;
}

.amit{
    align-self: center;
    display: flex;
    flex-direction: row;
}

.amit-pic{
    width: 16rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 3px #000000;
}

.amit-text{
    font-size:1.5rem;
    font-family: nunito-medium;
    margin-left: 4rem;
    line-height: 2rem;
    color: #146b99;

}

.ripdaman{
    align-self: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;

}

.ripdaman-pic{
    width: 16rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 3px #000000;
}

.ripdaman-text{
    font-size: 1.5rem;
    font-family: nunito-medium;
    margin-left: 4rem;
    line-height: 2rem;
    color: #146b99;
}

.name-role{
    display: flex;
    flex-direction: column;
}

.name{
    font-size: 1.75rem;
    text-align: center;
    margin-top: 1rem;
    font-family: ubuntu-bold;
    color: #2585BB;
    line-height: 1.2rem;
}

.role{
    font-size: 1.75rem;
    text-align: center;
    margin-top: 1rem;
    font-family: ubuntu-medium;
    color: #2585BB;
}