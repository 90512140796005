:root {
    --primary: #2FB7F1;
    --text: #fff;
}

.btn{
    padding: 8px 20px;
    border-radius: 14px;
    outline: none;
    font-family: nunito-extra-bold;
    -moz-box-shadow: 0px 0px 6px #000000;
    -webkit-box-shadow: 0px 0px 6px #000000;
    box-shadow: 0px 0px 6px #525252;
    border: none;
    cursor: pointer;
}

.btn-mobile {
    outline: none;
}

.btn--primary {
    background-color: var(--primary);
    color: #fff;
}

.btn--outline {
    background-color: transparent;
    color: #2FB7F1;
    padding: 8px 20px;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px
}

.btn--large {
    padding: 8px 26px;
    font-size: 1.5rem;
    align-items: center;
    justify-items: center;
    width: 15rem;
    height: 3rem;
    transition: all 0.3s ease-out;
}

.btn--large-invert {
    padding: 8px 26px;
    font-size: 1.5rem;
    align-items: center;
    justify-items: center;
    width: 15rem;
    height: 3rem;
    background: #fff;
    color: #2FB7F1;
    transition: all 0.3s ease-out;
}

.btn--medium:hover, .btn--large:hover {
    background: #1e87b8;    
    border: 0px;
}

.btn--large-invert:hover{
    background: #1e87b8;
    color: var(--text);
    border: 0px;
}