.arm-container {
    height: 100%;
}

.title-container {
    width: 100%;
    height: 15rem;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arms-title{
    font-family: nunito-black;
    font-size: 4rem;
    opacity: 0;
    color: #165375;
    text-align: center;
    transition: opacity ease 1.25s;
}

.divider{
    height: 2px;
    width: 60%;
    display: flex;
    flex-direction: column;
    background-color: #165375;
    margin: 0 auto;
}

.arms-title.visible {
    opacity: 1;
}

.arm-content {
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
}

.arms {
    width: 60rem;
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
    align-items: center;
}

.arms.first {
    margin-top: 5rem;
}

.arms.second {
    display: flex;
    flex-direction: row;
    text-align: end;
    margin-left: auto;   
}

.arm-img {
    height: 15rem;
}

.arm-img.dark {
    color: #165375;
    margin-right: 5rem;
}

.arm-img.light {
    margin-left: 5rem;
}

.arm-text-container {
    width: 40rem;
}

.arm-text-container.second {
    height: fit-content;
    margin-left: auto;
}

.arm-headers {
    font-family: nunito-extra-bold;
    font-size: 2rem;
    color: #165375;
    line-height: 2.5rem;
}

.arm-paragraphs {
    font-family: nunito-bold;
    color: #165375;
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin-top: 1rem;
}
 
@media screen and (max-width: 1000px) {
    
    .arm-content {
        height: fit-content;
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto 0 auto;
        padding: 0 1rem;
    }

    .arms-title{
        font-size: 3rem;
    }

    .arms {
        width: 20rem;
    }

    .arms.second {
        width: 20rem;
        margin-left: 0;
        justify-content: center;
    }
    
    .arm-img {
        display: none;
    }
    
    .arm-img.dark {
        margin-right: 0;
    }
    
    .arm-img.light {
        margin-left: 0;
    }

    .arm-text-container {
        width: fit-content;
        text-align: center;
    }

    .arm-text-container.second {
        height: fit-content;
        margin-left: 0;
    }

    .arm-headers {
        font-size: 1.5rem;
    }

    .arm-paragraphs{
        font-size: 1rem;
    }

}