.support-wrapper{
    background: white;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    height: 35rem;
    width: 100%;
    margin-top: 1rem;
    flex-direction: row;
}

.helping-hands{
    opacity: 0;
    transform: translateX(25%);
    transition: all ease 1.25s;
    margin-left: 1rem;
    width: 480px;
    border-radius: 1rem;
    opacity: 0;
    transform: translateX(25%);
    transition: all ease 1.25s;
    margin-left: 1rem;
}

.helping-hands.visible {
    opacity: 1;
    transform: translateX(0);
}

.sup-mission-wrapper{
    opacity: 0;
    transition: opacity ease 1.25s;
    max-width: 38vw;
    display: flex;
    flex-direction: column;
}

.sup-mission-wrapper.visible{
    opacity: 1;
}

.mission-container {
    width: 100%;
}

.sup-mission-heading{
    font-family: nunito-black;
    font-size: 4rem;
    color: #2585BB;
    margin-bottom: 2rem;
}

.sup-mission-statement{
    font-family: nunito-extra-bold;
    font-size: 1.6rem;
    color: #2585BB;
}

.support-us-header {
    height: 20rem;
    background: #2585BB;
    display: flex;
    justify-content: center;
    align-items: center;
}

.support-us-title {
    color: #ffffff;
    font-family: nunito-black;
    font-size: 4rem;
    opacity: 0;
    transition: opacity ease 1.25s;
}

.support-us-title.visible {
    opacity: 1;
}

.mission-content {
    font-family: nunito-extra-bold;
    color: #2585BB;
    width: fit-content;
    margin: 10rem auto;
    opacity: 0;
    transform: translateY(50%);
    transition: all ease 1.25s;
}

.mission-content.visible {
    opacity: 1;
    transform: translateY(0);
}

.mission-title {
    font-family: nunito-extra-bold;
    color: #2585BB;
    font-size: 3rem;
}

.mission-body {
    margin-top: 1rem;
    line-height: 2rem;
    font-size: 1.75rem;
    font-family: nunito-bold;
    color: #2585BB;
}

.outreach-email {
    text-decoration: underline;
    cursor: pointer;
    font-family: nunito-extra-bold;
    font-size: 1.8rem;
}

.outreach-email:hover {
    text-decoration: none;
}

@media screen and (max-width: 1200px) {

    .support-us-title {
        font-size: 3rem;
    }

    .mission-content {
        margin: 9rem auto;
    }

    .mission-title {
        font-size: 2rem;
    }
    .mission-body {
        margin-top: 1rem;
        line-height: 2rem;
        font-size: 1.2rem;
        font-family: ubuntu-medium;
        color: #3e69dc;
    }

}

@media screen and (max-width: 800px) {

    .mission-content{
        text-align: center;
        padding: 1rem;
    }

}