.footer-container{
    background-color: #0A5B89;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* position: relative; */
    height: fit-content;
    bottom: 0;
    padding: 0 2rem;
}

.footer-section {
    display: flex;
    flex-direction: row;
}

.footer-div {
    margin-left: auto;

}

.footer-site-title{
    font-family: nunito-extra-bold;
    color: white;
    margin-top: 3.2rem;
    text-align: center;
    margin-bottom: 2rem;
}

.contact-us{
    font-family: nunito-extra-bold;
    color: white;
    font-size: 1.1rem;
    margin-top: 4rem;
    margin-left: 2rem;
}

.socials{
    text-align: right;
    margin-top: 2rem;
}

.footer-description{
    font-family: nunito-extra-bold;
    color: white;
    font-size: 1.1rem;
    margin-right: 2rem;  
    margin-top: 4rem;
}

.footer-description-phone{
    display: none;
    font-family: nunito-extra-bold;
    color: white;
    font-size: 1.1rem;
    margin-right: 2rem;
    margin-top: 2rem;
}

.instagram-icon{
    color: white;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-right: 0.5rem;
    cursor: pointer;
}

.instagram-icon:hover{
    color: turquoise;
    transition: ease 0.25s;
}

.twitter-icon{
    color: white;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-right: 0.5rem;
    cursor: pointer;
}

.twitter-icon:hover{
    color: turquoise;
    transition: ease 0.25s;
}

.facebook-icon{
    color: white;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-right: 2rem;
    cursor: pointer;
}

.facebook-icon:hover{
    color: turquoise;
    transition: ease 0.25s;
}

.footer-wave {
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.footer-wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
    transform: rotateY(180deg);
}

.footer-wave .shape-fill {
    fill: #0A5B89;
}


@media screen and (max-width: 1000px) {

    .footer-description{
        font-family: nunito=extra-bold;
        color: white;
        width: 15rem;
        margin-left: auto;
        font-size: 1rem;
        line-height: 1.25rem;
    }

}

@media screen and (max-width: 600px) {

    .footer-description{
        display: none;
    }

    .footer-description-phone{
        font-family: nunito-extra--bold;
        color: white;
        width: 12rem;
        display: initial;
        display: block;
        margin-left: auto;
        font-size: 1rem;
    }

}

@media screen and (max-width: 520px) {

    .contact-us {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        line-height: 1.4rem;
    }

    .footer-section {
        flex-direction: column;
    }

    .footer-div {
        margin: 0;
    }

    .socials{
        text-align: center;
        margin: 1rem auto 0 auto;
        width: fit-content;
    }

    .footer-description-phone{
        margin: 0;
        width: fit-content;
    }

    .facebook-icon {
        margin-right: 0;
    }

    .footer-site-title {
        margin-top: 2rem;
    }
    
}