.hero-container{
    /* position: fixed; */
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
}

.recogair {
    transition: all ease 1.5s;
    transform: translateX(50%);
    opacity: 0;
    width: fit-content;
    height: 30rem;
}

.recogair.visible {
    transform: translateX(0);
    opacity: 1;
}

.community {
    transition: all ease 2s;
    transform: translateX(-50%);
    opacity: 0;
    width: fit-content;
    height: 27rem;
    margin-right: 7.5rem;
}

.community.right {
    transform: translateX(50%);
    margin-right: 0rem;
    margin-left: 7.5rem;
}

.community.visible {
    transform: translateX(0);
    opacity: 1;
}

.slogan{
    font-family: nunito-black;
    font-size: 3.25rem;
    color: #2585BB;
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.init-title{
    font-family: nunito-extra-bold;
    font-size: 4rem;
    margin: 3rem 0rem 2rem 0rem;
    color: #fff;
}

.init-content-title {
    font-family: nunito-extra-bold;
    font-size: 2.5rem;
    margin: 2rem 0rem;
}

.init-desc{
    font-family: nunito-black;
    font-size: 2rem;
    margin-bottom: 3rem;
}

.learn-title{
    font-family: nunito-extra-bold;
    font-size: 3.5rem;
    margin-bottom: 1rem;
    color: #2585BB;
}

.learn-desc{
    font-family: nunito-black;
    font-size: 1.7rem;
    color: #2585BB;
    margin-bottom: 3rem;
}


.content-wrapper{
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35rem;
    width: 100%;
}

.text-wrapper{
    display: flex;
    flex-direction: column;
    opacity: 0;
    align-items: center;
    transition: opacity ease 1.25s;
    max-width: 38vw;
    margin-top: -2rem;
    float: right;
}

.text-wrapper.visible{
    opacity: 1;
}

.text_initiatives{
    display: flex;
    flex-direction: column;
    opacity: 0;
    align-items: center;
    transition: opacity ease 1.25s;
    max-width: 38vw;
    margin-top: -2rem;
    float: right;
}

.init-text-wrapper{
    display: flex;
    flex-direction: column;
}

.learn-text-wrapper{
    display: flex;
    flex-direction: column;
}

.text_initiatives.visible{
    opacity: 1;
}

.bubbles{
    display: flex;
    flex-direction: row;
    margin-bottom: -5rem;
    margin-top: -8rem;
    opacity: 0;
    transition: all ease 1.5s;
    transform: translateY(100%);
}

.bubbles.visible{
    transform: translateX(0);
    opacity: 1;
}

.left-bubbles{
    transition: all ease 2s;
}

.right-bubbles{
    margin-left: auto;
    transform: scale(-1, 1);
}

.wave {
    margin-top: 2rem;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
    transform: rotateY(180deg);
}

.wave .shape-fill {
    fill: #2585BB;
}

.wave-bottom {
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave-bottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.4px);
    height: 154px;
    transform: rotateY(180deg);
}

.wave-bottom .shape-fill {
    fill: #2585BB;
}

.wave-content-wrapper {
    background-color: #2585BB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
}

.wave-content {
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly; 
    background-color: #2585BB;
}

.initiatives-content {
    background-color: #2585BB;
    display: flex;
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
    padding: 2rem;
    transition: all ease 0.75s;
    color: #fff;
    flex: 1;
    height: 18rem;
    color: #fff;
}

.wave-content .shape-fill{
    fill: #2585BB;
}

.ntlogo{
    transition: all ease 3s;
    transform: translateX(50%);
    opacity: 0;
    width: fit-content;
    height: 25rem;
    margin-top: -5rem;
    margin-left: 6rem;
}

.ntlogo.visible{
    transform: translateX(0);
    opacity: 1;
}

.learn-about-us{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 8rem;
    margin-bottom: 6rem;
}

.learn{
    display: flex;
    flex-direction: column;
    opacity: 0;
    align-items: center;
    transition: opacity ease 1.25s;
    max-width: 38vw;
    margin-top: -2rem;
}

.learn.visible{
    opacity: 1;
}

.small-img {
    width: 0rem;
    visibility: hidden;
    transition: visibility 0.5s, opacity 0.5s linear, all 0.3s ease;
}

@media screen and (max-width: 1200px) {

    .slogan{
        font-size: 2rem;
        width: fit-content;
    }

    .site-title{
        font-size: 3rem;
    }

    .text-wrapper{
        max-width: fit-content;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .content-wrapper{
        padding: 0 2.5rem;
        margin: 4rem 0;
        text-align: center;
        height: fit-content;
    }

    .laptop-img{
        width: 80vw;
        max-width: fit-content;
    }

    .hero-container{
        width: 100vw;
    }

    .wave-content {
        padding: 3rem 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        height: fit-content;
        max-width: 100%;
        width: 100%;
    }

    .text_initiatives {
        max-width: 100%;
        width: 100%;
    }

    .community {
        height: 16rem;
        margin: 2rem auto;
    }
    
    .init-text-wrapper {
        max-width: 100%;
        width: 100%;
    }

    .init-title {
        font-size: 2.5rem;
    }

    .init-desc {
        font-size: 1.5rem;
        /* max-width: fit-content; */
        width: fit-content;
        margin: 0 1rem 3rem 1rem;
    }

    .ntlogo {
        display: none;
    }

    .learn-about-us {
        width: 100%;
        margin: 5rem auto;
        text-align: center;
        padding: 2rem;
    }

    .learn {
        max-width: fit-content;
    }

    .learn-text-wrapper {
        width: fit-content;
    }

    .learn-title {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 850px) {
    
    .recogair{
        display: none;
    }

    .bubbles {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        align-items: center;
    }

    .right-bubbles {
        display: none;
    }
}