.upcoming-events{
    display: flex;
    flex-direction: column;
    width: 64vw;
    text-align: center;
    margin: 0 auto 0 auto;
}

.event-header{
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-family: ubuntu-bold;
    font-size: 4rem;
    color: #244a64;
}

.event-desc{
    font-family: ubuntu-bold;
    font-size: 1.5rem;
    color: #44bfd2;
    text-align: center;
    margin-bottom: 2rem;
    text-align: left;
    margin-left: 4rem;
    font-family: ubuntu-medium;
}

.workshop-desc{
    font-family: ubuntu-bold;
    font-size: 1.5rem;
    color: #44bfd2;
    text-align: center;
    margin-bottom: 2rem;
}
.event-card {
    height: fit-content;
    width: 40rem;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding: 4rem;
    -moz-box-shadow: 0px 0px 35px #000000;
    -webkit-box-shadow: 0px 0px 35px #000000;
    box-shadow: 0px 0px 35px #000000;
    border: none;
    font-family: ubntu-medium;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #44bfd2;
    -moz-border-radius-topleft:9px;
    -moz-border-radius-topright:9px;
    -moz-border-radius-bottomleft:9px;
    -moz-border-radius-bottomright:9px;
    -webkit-border-top-left-radius:9px;
    -webkit-border-top-right-radius:9px;
    -webkit-border-bottom-left-radius:9px;
    -webkit-border-bottom-right-radius:9px;
    border-top-left-radius:9px;
    border-top-right-radius:9px;
    border-bottom-left-radius:9px;
    border-bottom-right-radius:9px;
}

@media screen and (max-width: 1200px) {
    .event-card{
        width: 20rem;
        margin-top: 2rem;
        padding: 2rem;
    }

    .event-header{
        font-size: 2.5rem;
        margin: 2rem auto 2rem auto;
    }

    .upcoming-events{
        width: 20rem;
    }

    .event-desc{
        margin-left: 0;
        text-align: center;
    }
}