.supporter-wrapper{
    background: white;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    height: 35rem;
    width: 100%;
    margin-top: 1rem;
    background-color: #2585BB;
}

.rising-youth{
    opacity: 0;
    transform: translateX(25%);
    transition: all ease 1.25s;
    margin-top: -4rem;
    width: 400px;
    opacity: 0;
    transform: translateX(25%);
    transition: all ease 1.25s;
}

.rising-youth.visible {
    opacity: 1;
    transform: translateX(0);
}

.supporter-mission-wrapper{
    opacity: 0;
    transition: opacity ease 1.25s;
    max-width: 38vw;
    display: flex;
    flex-direction: column;
}

.supporter-mission-wrapper.visible{
    opacity: 1;
}

.supporter-container {
    width: 100%;
}

.supporter-heading{
    font-family: nunito-black;
    font-size: 4rem;
    color: #ffffff;
    margin-bottom: 2rem;
}